import { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Toolbar,
  Button,
  MenuList,
  MenuItem,
} from "@mui/material";

import ChildSelector from "../ChildSelector";
import Logo from "../../img/pinwheel-logo-icon-blue.svg?react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrid,
  faAddressBook,
  faMessageLines,
  faMobileScreenButton,
  faCircleCheck,
  faCalendarDays,
  faMessageQuestion,
  faPlus,
  faBullhorn,
} from "@fortawesome/pro-light-svg-icons";
import { pinwheelPalette } from "../../styles/theme";

import "./navigation.sass";
import ModalWindow from "../ModalWindow";
import UpvotyWidget, { UpvotyOptions } from "../UpvotyWidget";
import { NotificationDot } from "../NotificationDot";
import { useGetContacts } from "@/routes/new-contacts/hooks";
import { SafeListMode } from "@/openapi";
import ChildContext from "@/context/child";

interface Props {
  upvotyWidgetOptions: UpvotyOptions
}

const Navigation = ({ upvotyWidgetOptions }: Props) => {
  const [isNewVersion, setIsNewVersion] = useState(
    // eslint-disable-next-line no-undef
    true
  );
  const { child } = useContext(ChildContext);
  const [isFeatureWidgetOpen, setFeatureWidgetOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pendingContacts } = useGetContacts();

  if (isNewVersion) {
    // eslint-disable-next-line no-undef
    localStorage.setItem("appVersion", import.meta.env.VITE_VERSION!);
    setTimeout(() => {
      setIsNewVersion(false);
    }, 3000);
  }

  const handleClick = () => {
    navigate(
      {
        pathname: "/sign-up-child"
      },
      {
        state: {
          fromDashboard: true
        }
      }
    );
  };


  const drawer = (
    <div>
      <Toolbar disableGutters={true} sx={{ my: 2, mx: 0 }}>
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
      </Toolbar>
      <List className="button-holder">
        <ListItem>
          <ChildSelector />
        </ListItem>
        <ListItem>
          <Button endIcon={<FontAwesomeIcon icon={faPlus} />} onClick={handleClick} size="large" className="addChildDesktopBtn" fullWidth>
            Add Child
          </Button>
        </ListItem>
      </List>
      <Divider sx={{ borderColor: pinwheelPalette.lightGrey[100], height: "1px" }} />
      <MenuList className="list-menu">
        <MenuItem className={`${location.pathname === "/dashboard" ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/dashboard">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faGrid} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Dashboard</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
        <MenuItem className={`${location.pathname.includes("/contacts") ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/contacts">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faAddressBook} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Contacts</ListItemText>
              {child?.configuration.safelistMode === SafeListMode.Approved && pendingContacts.length > 0 ? (
                <NotificationDot
                  sx={{
                    fontSize: pendingContacts.length > 9 ? "10px!important" : "12px",
                  }}
                >
                  {pendingContacts.length > 9 ? "9+" : pendingContacts.length}
                </NotificationDot>
              ) : null}
            </NavLink>
          </Box>
        </MenuItem>
        <MenuItem className={`${location.pathname.includes("/phone-history") ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/phone-history">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faMessageLines} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Phone History</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
        <MenuItem className={`${location.pathname.includes("/apps") ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/apps">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faMobileScreenButton} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Apps</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
        <MenuItem className={`${location.pathname === "/routines" ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/routines">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faCircleCheck} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Routines</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
        <MenuItem className={`${location.pathname === "/calendar" ? "active" : ""}`}>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <NavLink to="/calendar">
              <ListItemIcon sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>
                <FontAwesomeIcon icon={faCalendarDays} />
              </ListItemIcon>
              <ListItemText sx={{ ".active > &": { color: (theme) => theme.palette.primary.main } }}>Schedule</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
        <Divider sx={{ borderColor: pinwheelPalette.lightGrey[100], height: "1px" }} />
        <MenuItem>
          <Box className="menu-label-container" display="flex" alignItems="center">
            <ListItemText>Support</ListItemText>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box className="menu-item-container" display="flex" alignItems="center">
            <Link underline="none" href="https://support.pinwheel.com/" target="_blank" rel="noreferrer">
              <ListItemIcon className="menu-icons">
                <FontAwesomeIcon icon={faMessageQuestion} />
              </ListItemIcon>
              <ListItemText>Help</ListItemText>
            </Link>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box className="menu-item-container stonly-product-news-selector" display="flex" alignItems="center">
            <NavLink to="#">
              <ListItemIcon>
                <FontAwesomeIcon icon={faBullhorn} />
              </ListItemIcon>
              <ListItemText>Product News</ListItemText>
            </NavLink>
          </Box>
        </MenuItem>
      </MenuList>
    </div>
  );

  return (
    <>
      <Box
          component="nav"
          aria-label="mailbox folders"
          className="navbar"
          sx={{
            borderBottom: `1px solid ${pinwheelPalette.grey[5]}`,
            minHeight: {lg: "100vh"}
          }}
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", position: "absolute",  right: 0, borderColor: pinwheelPalette.lightGrey[100] }
          }}
        >
          {drawer}
        </Drawer>
        
      </Box>
      
      <ModalWindow
        headerText="Feature Request"
        showModal={isFeatureWidgetOpen}
        setShowModal={setFeatureWidgetOpen}
        renderBody={() => <UpvotyWidget options={upvotyWidgetOptions} />}
        isLarge
        cancelButtonText="Close"
      />
    </>
  );
};

export default Navigation;
