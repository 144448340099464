import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ChildrenContext, ChildrenProvider } from "../../context/children";
import { MeProvider } from "../../context/me";
import { useMeContext } from "@/util/me-context";
import { APIContext, APIProvider } from "../../context/api";

import Navigation from "../../components/Navigation";
import Header from "../../components/Header";
import NoChild from "../../components/NoChild";
import MobileFooter from "../../components/MobileFooter";
import { CAREGIVER_NATIVE_VERSION_FOR_SKIP_BILLING_IOS, compareDesktopVersion, deviceType, getReactNativeAppVersion, getReactNativeOS, getUpvotyToken, trackPage } from "../../util/helper";
import ModalBirthDate from "../../components/ModalBirthDate";
import moment from "moment";
import { ChildResponse, PaymentStatusResponse } from "../../openapi";
import { UpvotyOptions } from "../../components/UpvotyWidget";
import { Grid, Box } from "@mui/material";
import { sendUserAttributeVerificationCode } from "aws-amplify/auth";
import { LinkBilling } from "../../routes/account/LinkBilling";
import { PaymentStatus } from "../../openapi/models/payment-status";
import { UnpaidSubscription } from "../../routes/account/UnpaidSubscription";
import { handleApiError } from "../../util/error-handlers";
import { useIsMobile } from "@/hooks";
import { NotificationProvider, useNotificationContext } from "@/components/NotificationCenter/notification-context";
import NoSubscriptionModal from "@/components/NoSubscriptionModal";
import Loader from "@/components/CircularLoader";
import { ChildProvider, useChildContext } from "@/context/child";

const reactNativeOsName = getReactNativeOS();
const reactNativeAppVersion = getReactNativeAppVersion();

const newLayoutRoutes = ["/phone-history"];

const checkIfNewLayoutRoute = (pathname: string): boolean => {
  return newLayoutRoutes.some((route) => pathname.includes(route));
}

const DashboardPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getCaregiversService } = useContext(APIContext);
  const { data: childrenUsers } = useContext(ChildrenContext);
  const { data: user, logout, caregiverData, refreshCaregiverData } = useMeContext();
  const { child, setChild } = useChildContext();
  const [noBirthDayChilds, setNoBirthDayChilds] = useState<ChildResponse[]>([]);
  const [showConnectBillingBanner, setShowConnectBillingBanner] = useState(false);
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);
  const [paymentStatusResponse, setPaymentStatusResponse] = useState<PaymentStatusResponse | undefined>(undefined);
  const [isRequestingPaymentStatus, setIsRequestingPaymentStatus] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { markAsRead } = useNotificationContext();
  const isIOS = useMemo(() => deviceType === "mobile" && reactNativeOsName === "ios" && reactNativeAppVersion && compareDesktopVersion(reactNativeAppVersion, CAREGIVER_NATIVE_VERSION_FOR_SKIP_BILLING_IOS), []);

  const handleResizeAndOrientation = () => {
    setWindowInnerHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeAndOrientation);
    // Listening to orientation change as iOS RN WebView is not firing resize events between landscape to portrait.
    window.addEventListener("orientationchange", handleResizeAndOrientation);
    return () => {
      window.removeEventListener("resize", handleResizeAndOrientation);
      window.removeEventListener("orientationchange", handleResizeAndOrientation);
    };
  }, []);

  useEffect(() => {
    trackPage(location.pathname);
  }, [location]);

  const refreshPaymentStatus = useCallback(async (nextPaymentCheckAt: number) => {
    try {
      const caregiversService = await getCaregiversService();
      const paymentStatusCheckResult = (await caregiversService.getSubscriptionPaymentStatus()).data;
      setPaymentStatusResponse(paymentStatusCheckResult);
      localStorage.setItem("pcat", String(nextPaymentCheckAt));
    } catch(error) {
      handleApiError(error);
    } finally {
      setIsRequestingPaymentStatus(false);
    }
  }, [getCaregiversService]);

  useEffect(() => {
    if (child?.deviceInfos && location.pathname === "/") {
      if (child.deviceInfos.length > 0) {
        navigate("/phone-history", { replace: true });
      } else {
        navigate("/dashboard", { replace: true });
      }
    }
  }, [child, location.pathname, navigate])

  useEffect(() => {
    // Make payment status check api call:
    // 1. after login
    // 2. page reload
    // 3. once in every day
    // LocalStorage will keep next timestamp for making api call. (Simpler approach for first release)
    // A timer will checking if current time has elapsed the next payment check timestamp before making api call.

    const oneHourInMs = 60 * 60 * 1000; // 1h in milliseconds
    // Run timer in every hour so that within 1 hour of next payment check timestamp we make the call.
    const checkPaymentTimerInterval = oneHourInMs;
    const dayInMs = 24 * oneHourInMs; // 24h in milliseconds

    const checkPayment = async () => {
      const now = new Date().getTime();
      const paymentCheckAt = Number(localStorage.getItem("pcat")) || 0;
      const nextPaymentCheckAt = now + dayInMs;

      if (paymentStatusResponse === undefined || paymentCheckAt === 0 || typeof paymentCheckAt !== "number") {
        // Uninitialized or malformed pcat value.
        await refreshPaymentStatus(nextPaymentCheckAt);
      } else if (now > paymentCheckAt) {
        // Time to check payment status again.
        await refreshPaymentStatus(nextPaymentCheckAt);
      }
    };

    // Run payment check once at component mount (after login, page reload)
    checkPayment();

    // Then run payment check after every given interval.
    const checkPaymentTimer = setInterval(checkPayment, checkPaymentTimerInterval);

    return () => {
      clearInterval(checkPaymentTimer);
    };
  }, [paymentStatusResponse, refreshPaymentStatus]);

  const [ssoToken, setToken] = useState<string>("");
  const [upvotyWidgetOptions, setUpvotyWidgetOptions] = useState<UpvotyOptions>({} as UpvotyOptions);

  const [showBirthDateModal, setShowBirthDateModal] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const childrenId = urlSearchParams.get("childrenId");
    const notificationId = urlSearchParams.get("notificationId");
    if (notificationId) {
      markAsRead(notificationId);
      urlSearchParams.delete("notificationId");
    }
    if (childrenId) {
      const child = childrenUsers?.find((child) => child.id === childrenId);
      if (child) {
        setChild(child);
        urlSearchParams.delete("childrenId");
        navigate(
          {
            pathname: location.pathname,
            search: urlSearchParams.toString(),
          },
          {
            replace: true,
          },
        );
        return;
      } else {
        urlSearchParams.delete("childrenId");
        navigate(
          {
            pathname: location.pathname,
            search: urlSearchParams.toString(),
          },
          {
            replace: true,
          },
        );
      }
    }
  }, [childrenUsers, location.pathname, location.search, markAsRead, navigate, setChild]);

  useEffect(() => {
    if (caregiverData) {
      if (caregiverData.billingEmail) {
        setShowConnectBillingBanner(false);
      } else {
        setShowConnectBillingBanner(true);
      }
    }
  }, [caregiverData]);

  const currentDate = moment();

  const checkLocalStorage =
    !localStorage.getItem("birthdayCheked") ||
    currentDate.diff(moment(new Date(localStorage.getItem("birthdayCheked")!)), "days") > 6;

  useEffect(() => {
    if (childrenUsers && childrenUsers.length > 0) {
      const childsWithoutBirthday = childrenUsers.filter(child => !child.birthYearMonth);
      if (childsWithoutBirthday.length > 0 && checkLocalStorage) {
        setShowBirthDateModal(true);
        setNoBirthDayChilds(childsWithoutBirthday);
        localStorage.setItem("birthdayCheked", JSON.stringify(currentDate));
      }
    }
  }, [checkLocalStorage, childrenUsers, currentDate]);

  const showUpvotyBoard = async (boardHash: string): Promise<void> => {
    const token = await getUpvotyToken(user!);
    setToken(token || "");
    setUpvotyWidgetOptions({
      ssoToken,
      baseUrl: "pinwheel.upvoty.com",
      boardHash: boardHash
    });
  };

  useEffect(() => {
    if (user && user.attributes.email_verified !== true) {
      // send the user a verification code and after its done, redirect
      sendUserAttributeVerificationCode({ userAttributeKey: "email" }).then(() => {
        navigate({
          pathname: "/verify-email",
          search: `redirect=${location.pathname}`,
        });
      });
    }
  }, [location.pathname, navigate, user]);


  const isPinwheelUser = useMemo(() => {
    return user?.attributes.email?.endsWith("@pinwheel.com");
  }, [user]);

  if (user?.attributes.email_verified !== true) {
    return null;
  }

  const excludeScrollRoutes = ["/phone-history", "/phone-history/call", "/apps"]

  const handleBillingUpdated = () => {
    refreshCaregiverData?.();
  }

  if (!caregiverData || isRequestingPaymentStatus) return <Loader />;
  if (paymentStatusResponse?.paymentStatus === PaymentStatus.NoBillableSubscription && isIOS) return <NoSubscriptionModal refreshPaymentStatus={refreshPaymentStatus} />;

  return (
    <>
      <ModalBirthDate
        showModal={showBirthDateModal}
        setShowModal={setShowBirthDateModal}
        childrenList={noBirthDayChilds}
      />
      {
        // If billing email is not available, then show the link billing email component
        (!paymentStatusResponse || showConnectBillingBanner) && !isPinwheelUser ?
          <LinkBilling
            refreshCaregiverData={handleBillingUpdated}
            refreshPaymentStatus={refreshPaymentStatus}
          /> 
          : 
          (paymentStatusResponse?.paymentStatus === PaymentStatus.UnpaidSubscription) ?
            <UnpaidSubscription paymentStatusResponse={paymentStatusResponse} setPaymentStatusResponse={setPaymentStatusResponse} /> 
          : 
          <Box sx={{}}>
            <Grid sx={{ display: "flex" }}>
              {!isMobile ?
                <Grid
                  className="sidebar"
                  position="relative"
                  flex={{ lg: "0 0 215px" }}
                >
                  <Navigation upvotyWidgetOptions={upvotyWidgetOptions} />
                </Grid>
                : null}
              <Grid
                flex={{ lg: 1 }}
                display="flex"
                flexDirection="column"
                sx={{ overflow: "auto", height: `${windowInnerHeight}px` }}
                container
              >
                <Box>
                  <Header
                    onLogoutPress={logout!}
                    upvotyWidgetOptions={upvotyWidgetOptions}
                    showUpvotyBoard={showUpvotyBoard}
                    showConnectBillingBanner={showConnectBillingBanner}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    overflowY: {
                      xs: !excludeScrollRoutes.includes(location.pathname) ? "auto" : "hidden",
                      lg: "auto",
                    },
                    overflowX: "hidden",
                    maxWidth: "100%",
                    ...(checkIfNewLayoutRoute(location.pathname) ? { display: "flex", flexDirection: "column" } : {}),
                  }}
                >
                  <Box
                    p={checkIfNewLayoutRoute(location.pathname) ? 0 : 2}
                    pb={0}
                    sx={{
                      ...(checkIfNewLayoutRoute(location.pathname) ? { height: "100%", display: "flex", flex: 1 } : {}),
                    }}
                  >
                    {!child && location.pathname !== "/account" ? <NoChild /> : children}
                  </Box>
                </Box>
                {isMobile ?
                  <Box>
                    <MobileFooter upvotyWidgetOptions={upvotyWidgetOptions} showUpvotyBoard={showUpvotyBoard} />
                  </Box> : null}
              </Grid>
            </Grid>
          </Box>
      }
    </>
  );
}

const DashboardPageWithWrappers: React.FC = () => (
  <APIProvider>
    <MeProvider>
      <ChildrenProvider>
        <ChildProvider>
          <NotificationProvider>
            <DashboardPage>
              <Outlet />
            </DashboardPage>
          </NotificationProvider>
        </ChildProvider>
      </ChildrenProvider>
    </MeProvider>
  </APIProvider>
);

export default DashboardPageWithWrappers;
