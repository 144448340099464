import React, { Suspense, useEffect, useState } from "react";
import { Route, HashRouter, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { ThemeProvider } from "@mui/material/styles";
import Loader from "./components/CircularLoader";

const Dashboard = lazyWithErrorHandling(() => import("./routes/dashboard/"));
const Contacts = lazyWithErrorHandling(() => import("./routes/new-contacts/"));
const Routines = lazyWithErrorHandling(() => import("./routes/routines/"));
const ForgotPassword = lazyWithErrorHandling(() => import("./routes/forgot-password"));
const ResetPassword = lazyWithErrorHandling(() => import("./routes/reset-password"));
const Welcome = lazyWithErrorHandling(() => import("./routes/welcome"));
const Login = lazyWithErrorHandling(() => import("./routes/login"));
const OnBoarding = lazyWithErrorHandling(() => import("./routes/on-boarding"));
const SignUpChild = lazyWithErrorHandling(() => import("./routes/sign-up-child"));
const Apps = lazyWithErrorHandling(() => import("./routes/apps"));
const Calendar = lazyWithErrorHandling(() => import("./routes/calendar"));
const Account = lazyWithErrorHandling(() => import("./routes/account"));
const Conversations = lazyWithErrorHandling(() => import("./routes/conversations"));
const VerifyEmail = lazyWithErrorHandling(() => import("./routes/verifyEmail"));
import DashboardPage from "./wrappers/dashboard-page";
import AuthPage from "./wrappers/auth-page";
import OnBoardingPage from "./wrappers/on-boarding-page";
import theme from "./styles/theme";
import Error from "./components/Error";

import "./styles/animate.css";
import "./styles/form.sass";
import "./styles/global.sass";
import { lazyWithErrorHandling } from "./lazyWithErrorHandling";
import { rnSetAppData } from "./util/fcm-notification-helper";
import {
  biometricLoginFailed,
  setHasUserEnabledBiometric,
  setIsBiometricSupported,
  biometricLogin,
  setNativeAppDeviceId,
  biometricLoginCancel
} from "./util/mobile-biometric-helper";
import { deviceType } from "./util/helper";
import { Box } from "@mui/material";
import Logo from "./img/pinwheel-logo-icon-white_2.svg?react";
import { useOnlineStatus } from "./hooks/use-online-status";
import ErrorInfo from "./context/errorInfo";
import SnackbarProvider from "./context/snackbar";

ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_CODE!);

const AppContainer: React.FC = () => {
  window.setAppData = data => rnSetAppData(JSON.stringify(data));
  window.setIsBiometricSupported = setIsBiometricSupported;
  window.setHasUserEnabledBiometric = setHasUserEnabledBiometric;
  window.setNativeAppDeviceId = setNativeAppDeviceId;
  window.biometricLoginFailed = biometricLoginFailed;
  window.biometricLogin = biometricLogin;
  window.biometricLoginCancel = biometricLoginCancel;

  window.biometricLoginStart = () => {
    window.document.dispatchEvent(new CustomEvent("biometricLoginStart"));
  };

  const isOnline = useOnlineStatus();
  const [isBiometricLogginIn, setIsBiometricLogginIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: location.hash });
    ReactGA.event({
      category: "User",
      action: "Used a navigation"
    });
  }, [location.hash]);

  useEffect(() => {
    if (deviceType !== "mobile") return;

    const biometricLoginStartListener = () => {
      setIsBiometricLogginIn(true);
    };

    const biometricLoginEndListener = () => {
      setIsBiometricLogginIn(false);
    };

    window.document.addEventListener("biometricLoginStart", biometricLoginStartListener);
    window.document.addEventListener("biometricLoginEnd", biometricLoginEndListener);

    return () => {
      window.document.removeEventListener("biometricLoginStart", biometricLoginStartListener);
      window.document.removeEventListener("biometricLoginEnd", biometricLoginEndListener);
    };
  }, []);

  if (location.pathname === "/live-status") navigate("/dashboard", { replace: true });

  const suspend = (Children: React.ComponentType) => (
    <Suspense fallback={<Loader />}>
      <Children />
    </Suspense>
  );

  if (!isOnline) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorInfo />
      </ThemeProvider>
    );
  }

  if (isBiometricLogginIn) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
            position: "relative"
          }}
        >
          <Logo
            style={{
              width: "87vw",
              height: "14vh",
              animation: "spin 2s linear infinite"
            }}
          />
          <Loader />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<DashboardPage />}>
          <Route path="/" element={suspend(Dashboard)} />
          <Route path="/phone-history/*" element={suspend(Conversations)} />
          <Route path="/dashboard" element={suspend(Dashboard)} />
          <Route path="/dashboard/location-history" element={suspend(Dashboard)} />
          <Route path="/calendar" element={suspend(Calendar)} />
          <Route path="/contacts/:listType?/:contactId?" element={suspend(Contacts)} />
          <Route path="/apps/*" element={suspend(Apps)} />
          <Route path="/routines" element={suspend(Routines)} />
          <Route path="/account/*" element={<Navigate to="/settings/account" replace />} />
          <Route path="/settings/*" element={suspend(Account)} />
        </Route>
        <Route element={<AuthPage />}>
          <Route path="/welcome" element={suspend(Welcome)} />
          <Route path="/login" element={suspend(Login)} />
          <Route path="/forgot-password" element={suspend(ForgotPassword)} />
          <Route path="/reset-password" element={suspend(ResetPassword)} />
        </Route>
        <Route element={<OnBoardingPage />}>
          <Route path="/verify-email" element={suspend(VerifyEmail)} />
          <Route path="/on-boarding/*" element={suspend(OnBoarding)} />
          <Route path="/sign-up-child/*" element={suspend(SignUpChild)} />
        </Route>
        <Route element={<DashboardPage />}>
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <SnackbarProvider>
      <HashRouter>
        <AppContainer />
      </HashRouter>
    </SnackbarProvider>
  );
};

export default App;
