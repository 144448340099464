/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel Main Service API
 * Pinwheel Main Service API
 *
 * The version of the OpenAPI document: 2.22.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingEmailClaimRequest } from '../models';
// @ts-ignore
import { BillingEmailClaimResponse } from '../models';
// @ts-ignore
import { BillingEmailVerifyRequest } from '../models';
// @ts-ignore
import { BillingEmailVerifyResponse } from '../models';
// @ts-ignore
import { CancelSubscriptionsResponse } from '../models';
// @ts-ignore
import { CaregiverResponse } from '../models';
// @ts-ignore
import { CheckBillingResponse } from '../models';
// @ts-ignore
import { ChildResponse } from '../models';
// @ts-ignore
import { CreateChildRequest } from '../models';
// @ts-ignore
import { CustomerBillingInfoResponse } from '../models';
// @ts-ignore
import { CustomerUpdateRequest } from '../models';
// @ts-ignore
import { ErrorResponseWithCode } from '../models';
// @ts-ignore
import { FinalizeBillingRequest } from '../models';
// @ts-ignore
import { FinalizeBillingResponse } from '../models';
// @ts-ignore
import { PaymentMethodSetupIntentResponse } from '../models';
// @ts-ignore
import { PaymentStatusResponse } from '../models';
// @ts-ignore
import { SetupBillingRequest } from '../models';
// @ts-ignore
import { SetupBillingResponse } from '../models';
// @ts-ignore
import { UpgradeBillingRequest } from '../models';
// @ts-ignore
import { UpgradeBillingResponse } from '../models';
/**
 * CaregiversApi - axios parameter creator
 * @export
 */
export const CaregiversApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [childrenIds] 
         * @param {any} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptions: async (childrenIds?: any, cancelBark?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenIds !== undefined) {
                localVarQueryParameter['childrenIds'] = childrenIds;
            }

            if (cancelBark !== undefined) {
                localVarQueryParameter['cancelBark'] = cancelBark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBilling: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimBillingEmail: async (billingEmailClaimRequest: BillingEmailClaimRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingEmailClaimRequest' is not null or undefined
            assertParamExists('claimBillingEmail', 'billingEmailClaimRequest', billingEmailClaimRequest)
            const localVarPath = `/main/caregivers/billing/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingEmailClaimRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChild: async (createChildRequest: CreateChildRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChildRequest' is not null or undefined
            assertParamExists('createChild', 'createChildRequest', createChildRequest)
            const localVarPath = `/main/caregivers/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChildRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeBillingRequest} finalizeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeBilling: async (finalizeBillingRequest: FinalizeBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeBillingRequest' is not null or undefined
            assertParamExists('finalizeBilling', 'finalizeBillingRequest', finalizeBillingRequest)
            const localVarPath = `/main/caregivers/billing/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodSetupIntent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/paymentMethodSetupIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPaymentStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/paymentStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetupBillingRequest} setupBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupBilling: async (setupBillingRequest: SetupBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupBillingRequest' is not null or undefined
            assertParamExists('setupBilling', 'setupBillingRequest', setupBillingRequest)
            const localVarPath = `/main/caregivers/billing/setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setupBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerInfo: async (customerUpdateRequest: CustomerUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUpdateRequest' is not null or undefined
            assertParamExists('updateCustomerInfo', 'customerUpdateRequest', customerUpdateRequest)
            const localVarPath = `/main/caregivers/billing/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpgradeBillingRequest} upgradeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeBilling: async (upgradeBillingRequest: UpgradeBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upgradeBillingRequest' is not null or undefined
            assertParamExists('upgradeBilling', 'upgradeBillingRequest', upgradeBillingRequest)
            const localVarPath = `/main/caregivers/billing/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upgradeBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBillingEmail: async (billingEmailVerifyRequest: BillingEmailVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingEmailVerifyRequest' is not null or undefined
            assertParamExists('verifyBillingEmail', 'billingEmailVerifyRequest', billingEmailVerifyRequest)
            const localVarPath = `/main/caregivers/billing/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingEmailVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaregiversApi - functional programming interface
 * @export
 */
export const CaregiversApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaregiversApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [childrenIds] 
         * @param {any} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriptions(childrenIds?: any, cancelBark?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelSubscriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriptions(childrenIds, cancelBark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkBilling(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkBilling(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingEmailClaimResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimBillingEmail(billingEmailClaimRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChild(createChildRequest: CreateChildRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChild(createChildRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeBillingRequest} finalizeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeBilling(finalizeBillingRequest: FinalizeBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizeBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeBilling(finalizeBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBillingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaregiver(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaregiverResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaregiver(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildren(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildren(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethodSetupIntent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodSetupIntentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethodSetupIntent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionPaymentStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionPaymentStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetupBillingRequest} setupBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupBilling(setupBillingRequest: SetupBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupBilling(setupBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBillingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerInfo(customerUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpgradeBillingRequest} upgradeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeBilling(upgradeBillingRequest: UpgradeBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpgradeBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeBilling(upgradeBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingEmailVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyBillingEmail(billingEmailVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaregiversApi - factory interface
 * @export
 */
export const CaregiversApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaregiversApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [childrenIds] 
         * @param {any} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptions(childrenIds?: any, cancelBark?: any, options?: any): AxiosPromise<CancelSubscriptionsResponse> {
            return localVarFp.cancelSubscriptions(childrenIds, cancelBark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBilling(options?: any): AxiosPromise<CheckBillingResponse> {
            return localVarFp.checkBilling(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: any): AxiosPromise<BillingEmailClaimResponse> {
            return localVarFp.claimBillingEmail(billingEmailClaimRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChild(createChildRequest: CreateChildRequest, options?: any): AxiosPromise<ChildResponse> {
            return localVarFp.createChild(createChildRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeBillingRequest} finalizeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeBilling(finalizeBillingRequest: FinalizeBillingRequest, options?: any): AxiosPromise<FinalizeBillingResponse> {
            return localVarFp.finalizeBilling(finalizeBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo(options?: any): AxiosPromise<CustomerBillingInfoResponse> {
            return localVarFp.getBillingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver(options?: any): AxiosPromise<CaregiverResponse> {
            return localVarFp.getCaregiver(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren(options?: any): AxiosPromise<Array<ChildResponse>> {
            return localVarFp.getChildren(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodSetupIntent(options?: any): AxiosPromise<PaymentMethodSetupIntentResponse> {
            return localVarFp.getPaymentMethodSetupIntent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPaymentStatus(options?: any): AxiosPromise<PaymentStatusResponse> {
            return localVarFp.getSubscriptionPaymentStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetupBillingRequest} setupBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupBilling(setupBillingRequest: SetupBillingRequest, options?: any): AxiosPromise<SetupBillingResponse> {
            return localVarFp.setupBilling(setupBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: any): AxiosPromise<CustomerBillingInfoResponse> {
            return localVarFp.updateCustomerInfo(customerUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpgradeBillingRequest} upgradeBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeBilling(upgradeBillingRequest: UpgradeBillingRequest, options?: any): AxiosPromise<UpgradeBillingResponse> {
            return localVarFp.upgradeBilling(upgradeBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: any): AxiosPromise<BillingEmailVerifyResponse> {
            return localVarFp.verifyBillingEmail(billingEmailVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaregiversApi - object-oriented interface
 * @export
 * @class CaregiversApi
 * @extends {BaseAPI}
 */
export class CaregiversApi extends BaseAPI {
    /**
     * 
     * @param {any} [childrenIds] 
     * @param {any} [cancelBark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public cancelSubscriptions(childrenIds?: any, cancelBark?: any, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).cancelSubscriptions(childrenIds, cancelBark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public checkBilling(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).checkBilling(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).claimBillingEmail(billingEmailClaimRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateChildRequest} createChildRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public createChild(createChildRequest: CreateChildRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).createChild(createChildRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeBillingRequest} finalizeBillingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public finalizeBilling(finalizeBillingRequest: FinalizeBillingRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).finalizeBilling(finalizeBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public getBillingInfo(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).getBillingInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public getCaregiver(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).getCaregiver(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public getChildren(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).getChildren(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public getPaymentMethodSetupIntent(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).getPaymentMethodSetupIntent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public getSubscriptionPaymentStatus(options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).getSubscriptionPaymentStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetupBillingRequest} setupBillingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public setupBilling(setupBillingRequest: SetupBillingRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).setupBilling(setupBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUpdateRequest} customerUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).updateCustomerInfo(customerUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpgradeBillingRequest} upgradeBillingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public upgradeBilling(upgradeBillingRequest: UpgradeBillingRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).upgradeBilling(upgradeBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiversApi
     */
    public verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: AxiosRequestConfig) {
        return CaregiversApiFp(this.configuration).verifyBillingEmail(billingEmailVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
