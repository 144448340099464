import { useNavigate } from "react-router-dom";

import "./no-child.sass";
import { Button, Typography } from "@mui/material";
import { useMeContext } from "@/util/me-context";

const NoChild = () => {
  const { caregiverData } = useMeContext();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      {
        pathname: "/sign-up-child"
      },
      {
        state: {
          fromDashboard: true
        }
      }
    );
  };

  const handleBillingLinkClick = () => {
    navigate(
      {
        pathname: "/settings/account"
      },
      {
        state: {
          fromDashboard: true
        }
      }
    );
  };

  return (
    <div className="no-child">
      <Typography variant="h2">You haven&apos;t added a child to your account yet</Typography>
      <p className="no-child-message">Please add a child to set up your Pinwheel phone.</p>
      <Button size="large" onClick={handleClick}>
        Add Child
      </Button>

      {caregiverData && !caregiverData.billingEmail && (
        <div>
          <p className="billing-link-message">
            If your caregiver account email is different than the email you used for Pinwheel
            checkout, <br />
            please click the button below to link your billing email.
          </p>
          <Button onClick={handleBillingLinkClick}>Link Billing Email</Button>
        </div>
      )}
    </div>
  );
};

export default NoChild;
