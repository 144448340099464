import React, { useMemo, useState } from "react";
import { MeProvider } from "../../context/me";
import APIProvider from "../../context/api";
import ChildrenProvider from "@/context/children";
import { Outlet, useLocation } from "react-router-dom";
import { ChildProvider } from "@/context/child";


const OnBoardingPage: React.FC = () => {
  const location = useLocation();

  const [locationList ] = useState([
    "/verify-email",
    "/on-boarding/verification",
    "/on-boarding/childs-name",
    "/on-boarding/childs-birthday",
    "/on-boarding/billing",
    "/on-boarding/phone-credentials",
    "/on-boarding/block-time",
    "/on-boarding/default-time",
    "/on-boarding/default-apps",
    "/on-boarding/billing-options",
    "/on-boarding/billing-claim",
    "/on-boarding/billing-verification",
    "/on-boarding/billing-integration",
    "/sign-up-child",
    "/sign-up-child/naming",
    "/sign-up-child/birthdate",
    "/sign-up-child/billing",
    "/sign-up-child/credentials",
    "/sign-up-child/block-time",
    "/sign-up-child/default-time",
    "/sign-up-child/default-apps",
  ]);
  
  const isNotSignUpdPage = useMemo(()=> locationList.includes(location.pathname), [locationList, location.pathname]);
  
  return (
    <APIProvider>
      {isNotSignUpdPage ? (
        <MeProvider>
          {location.pathname !== "/on-boarding/verification" ? (
            <ChildrenProvider>
              <ChildProvider>
                <div className="auth-page">
                  <Outlet />
                </div>
              </ChildProvider>
            </ChildrenProvider>
          ) : (
            <div className="auth-page">
              <Outlet />
            </div>
          )}
        </MeProvider>
      ) : (
        <div className="auth-page">
          <Outlet />
        </div>
      )}
    </APIProvider>
  );
}

export default OnBoardingPage;
